import firebase from "firebase";

let firestore = null;

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: "${config.measurementId}"
};

firebase.initializeApp(config);

const db = () => {
  if (firestore === null) {
    firestore = firebase.firestore()

    if (process.env.REACT_APP_FIREBASE_MODE === 'development') {
      firebase.firestore.setLogLevel('debug');

      firestore.settings({
        host: 'localhost:8080',
        ssl: false
      });
    }
  }

  return firestore;
};

export default db;
