import React, { Fragment, FunctionComponent } from 'react';
import downloadJs from 'downloadjs';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import database from './Database';

const STRING_NUMBERS = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth'
];
const DOWNLOAD_ON_REQUEST_URL =
  'https://us-central1-team-maloney-v2.cloudfunctions.net/downloadOnRequest';
const MAX_LOADING_ASSIGNMENT_ATTEMPTS = 6;

export async function http(request: RequestInfo): Promise<any> {
  return await fetch(request);
}

const stringifyNumber = (n: number) => {
  if (n < 10) return STRING_NUMBERS[n];

  return n;
};

interface Move {
  name?: string;
  description: string;
  video: string;
  videoThumbnail: string;
  title: string;
}

interface Circuit {
  moves: Array<Move>;
  cardio: string;
  rest: string;
  instructions: string;
}

interface Workout {
  name: string;
  motivator: string;
  warmup: string;
  circuit: Circuit;
}

interface HeadingProps {
  extraClassNames?: string;
}

const Section: FunctionComponent = ({ children }) => {
  return <section className="pt-7">{children}</section>;
};

const Heading: FunctionComponent<HeadingProps> = ({
  children,
  extraClassNames
}) => {
  return (
    <h2
      className={`text-xs font-normal uppercase ${extraClassNames}`}
      style={{ color: 'rgb(118,118,118)' }}
    >
      {children}
    </h2>
  );
};

///////////////////////////////
// multiline instruction component
///////////////////////////////
type MultilineInstructionComponentProps = {
  instructions: string;
  name: string;
};

const MultilineInstructionComponent: any = (
  props: MultilineInstructionComponentProps
) => {
  if (props.instructions) {
    return props.instructions
      .split('\n')
      .map((instruction, instructionIndex) => (
        <p key={`${props.name}-${instructionIndex}`}>{instruction}</p>
      ));
  }

  return null;
};

///////////////////////////////
// move component
///////////////////////////////
type MoveComponentProps = {
  move: Move;
  index: number;
};

const MoveComponent: React.FC<MoveComponentProps> = props => {
  const { move, index } = props;
  const [isDownloading, setIsDownloading] = React.useState(false);

  const handleVideoDownload = (videoPath: string) => {
    if (!videoPath) return undefined;

    downloadJs(videoPath);
  };

  if (!move) return null;

  return (
    <div className="mt-4 shadow rounded">
      <div className="flex justify-between">
        <div className="w-full pt-2">
          <div className="flex justify-between items-center pl-3 pr-1">
            <Heading>{`${stringifyNumber(index)} move`}</Heading>

            <div className="flex-shrink-0">
              {!!move.video && (
                <button
                  disabled={isDownloading}
                  className="px-3 rounded leading-tight flex"
                  onClick={_e => handleVideoDownload(move.video)}
                >
                  <span
                    className="text-xs font-normal uppercase pr-1 pt-1 text-gray-500"
                    style={{ fontSize: '0.5rem' }}
                  >
                    Download video
                  </span>
                  <svg
                    className={
                      'h-5 w-5 ' +
                      (isDownloading ? 'text-gray-200' : 'text-gray-500')
                    }
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
          <div className="px-3 py-2">
            {Boolean(move.name) && (
              <div className="font-semibold">{move.name}</div>
            )}
            <div className="text-readable-gray">
              <MultilineInstructionComponent
                instructions={move.description}
                name="move-description"
              />
            </div>
          </div>
        </div>
      </div>
      {move.video && (
        <div className="relative rounded-b overflow-hidden">
          <video controls width="100%" className="relative z-20">
            <source src={`${move.video}#t=0.1`} type="video/mp4" />
          </video>
        </div>
      )}
    </div>
  );
};

///////////////////////////////
// circuit heading component
///////////////////////////////
type CircuitHeadingComponentProps = {
  circuit: Circuit;
  className?: string;
};

const CircuitHeadingComponent: React.FC<CircuitHeadingComponentProps> = ({
  circuit,
  className = ''
}) => {
  if (!circuit.instructions) return null;

  return (
    <div className={className}>
      <Heading>Circuit</Heading>
      <MultilineInstructionComponent
        instructions={circuit.instructions}
        name="circuit-instruction"
      />
    </div>
  );
};

///////////////////////////////
// circuit component
///////////////////////////////
type CircuitComponentProps = {
  circuit: Circuit;
};

const CircuitComponent: React.FC<CircuitComponentProps> = ({ circuit }) => (
  <Fragment>
    <Section>
      <CircuitHeadingComponent circuit={circuit} />

      {circuit.moves &&
        circuit.moves.map((move, move_index) => (
          <MoveComponent
            move={move}
            index={move_index + 1}
            key={`move-${move_index}`}
          />
        ))}
    </Section>
    {circuit.cardio && (
      <Section>
        <Heading>Cardio</Heading>
        <MultilineInstructionComponent
          instructions={circuit.cardio}
          name="circuit-cardio"
        />
      </Section>
    )}
    {circuit.rest && (
      <Section>
        <Heading>Rest</Heading>
        <MultilineInstructionComponent
          instructions={circuit.rest}
          name="circuit-rest"
        />
      </Section>
    )}
  </Fragment>
);

///////////////////////////////
// warmup
///////////////////////////////
type WarmupComponentProps = {
  description: string;
};

const WarmupComponent: React.FC<WarmupComponentProps> = ({ description }) => (
  <Section>
    <Heading>Warm Up</Heading>
    <MultilineInstructionComponent
      instructions={description}
      name="section-description"
    />
  </Section>
);

///////////////////////////////
// workout description
///////////////////////////////
type WorkoutDescriptionComponentProps = {
  circuit: Circuit;
};

const WorkoutDescriptionComponent: React.FC<WorkoutDescriptionComponentProps> = ({
  circuit
}) => (
  <Section>
    <CircuitHeadingComponent circuit={circuit} className="mb-7" />
    <p className="text-center">—Mike</p>
    <div className="flex flex-row h-48 items-center justify-between mt-4">
      <div className="h-20 flex-shrink-0">
        <img
          alt="Mike Maloney Signature"
          src="/signature.png"
          className="h-full"
        />
      </div>

      <img
        alt="Mike Maloney"
        src="/mike-maloney-1.png"
        className="h-full flex-shrink-1 h-full"
      />
    </div>
  </Section>
);

///////////////////////////////
// workout
///////////////////////////////
type WorkoutComponentProps = {
  workout: Workout;
};

const WorkoutComponent: React.FC<WorkoutComponentProps> = props => {
  const { workout } = props;

  if (!workout) {
    return null;
  }

  return (
    <div className="max-w-md mx-auto p-4 pb-0">
      <div className="flex">
        <img alt="Team Maloney" src="/logo.png" className="h-8" />
        <Heading extraClassNames="pl-4 pt-4">This week's workout</Heading>
      </div>

      {!!workout.motivator && (
        <h1 className="pt-4 text-xl font-semibold lh-solid">
          {workout.motivator}
        </h1>
      )}

      {!!workout.warmup && <WarmupComponent description={workout.warmup} />}
      {!!workout.circuit && <CircuitComponent circuit={workout.circuit} />}
      {!!workout.circuit && (
        <WorkoutDescriptionComponent circuit={workout.circuit} />
      )}
    </div>
  );
};

interface IAssignmentDoc {
  exists: any;
  data: () => any;
}

const fetchAssignmentWithDelay = (
  assignmentID: string,
  delay: number
): Promise<IAssignmentDoc | null> => {
  return new Promise((resolve, _) => {
    console.log('waiting ', delay);

    setTimeout(async () => {
      const itemRef = await database()
        .collection('assignments')
        .doc(assignmentID);

      return itemRef
        .get()
        .then((doc: IAssignmentDoc) => {
          return resolve(doc.exists ? doc : null);
        })
        .catch((err: any) => {
          console.log('Error getting document:', err);
          return resolve(null);
        });
    }, delay);
  });
};

class WorkoutComponentLoader extends React.Component<
  { afterForcedSync: boolean },
  { assignmentID: string; loadingDone: boolean; assignment: any }
> {
  constructor(props: any) {
    super(props);

    const { match } = props;
    const { params } = match;
    const { assignmentID } = params;

    this.state = {
      loadingDone: false,
      assignment: undefined,
      assignmentID
    };
  }

  componentDidMount = () => {
    const { assignmentID } = this.state;
    if (assignmentID) {
      this.loadAssignment(assignmentID, true, 0);
    }
  };

  useAssignmentData = (doc: IAssignmentDoc) => {
    const assignment = doc.data();

    console.log('assignment', assignment);
    this.setState({
      assignment: assignment,
      loadingDone: true
    });
  };

  async loadAssignment(
    assignmentID: string,
    forceSync: boolean,
    attempt: number
  ): Promise<any> {
    if (attempt < MAX_LOADING_ASSIGNMENT_ATTEMPTS) {
      fetchAssignmentWithDelay(assignmentID, attempt * 1000).then(async doc => {
        if (doc) {
          this.useAssignmentData(doc);
          return;
        } else {
          if (forceSync) {
            await http(DOWNLOAD_ON_REQUEST_URL);
          }

          return await this.loadAssignment(assignmentID, false, attempt + 1);
        }
      });
    } else {
      this.setState({
        loadingDone: true
      });
    }
  }

  render = () => {
    const { loadingDone, assignment } = this.state;

    if (loadingDone) {
      if (assignment) {
        return <WorkoutComponent workout={assignment} />;
      }

      return (
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="flex">
            <img alt="Team Maloney" src="/logo.png" className="h-8" />
            <h1 className="pl-4 text-xl font-semibold lh-solid">
              No document found
            </h1>
          </div>
        </div>
      );
    }

    return (
      <div className="absolute inset-0 flex justify-center items-center">
        <div className="flex">
          <img alt="Team Maloney" src="/logo.png" className="h-8" />
          <h1 className="pl-4 text-xl font-semibold lh-solid">Loading…</h1>
        </div>
      </div>
    );
  };
}

export class App extends React.Component<{}, { assignment: any }> {
  render = () => {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/" exact>
              :)
            </Route>
            <Route
              path="/b/:assignmentID"
              exact
              component={(props: any) => (
                <WorkoutComponentLoader afterForcedSync {...props} />
              )}
            />
            <Route
              path="/a/:assignmentID"
              component={(props: any) => <WorkoutComponentLoader {...props} />}
            />
          </Switch>
        </Router>
      </div>
    );
  };
}

export default App;
